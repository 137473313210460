export function secondsToDhms(seconds: number) {
  seconds = Number(seconds);
  const floor = Math.floor;
  const d = floor(seconds / (3600 * 24));
  const h = floor(seconds % (3600 * 24) / 3600);
  const m = floor(seconds % 3600 / 60);
  const s = Math.floor(seconds % 60);
  const dDisplay = d > 0 ? d + 'd ' : '';
  const hDisplay = h > 0 ? h + 'h ' : '';
  const mDisplay = m > 0 ? m + 'm ' : '';
  const sDisplay = s > 0 ? s + 's' : '';
  return dDisplay + hDisplay + mDisplay + sDisplay;
}

export function calcCycleLength(cycleStartBlock: number, cycleEndBlock: number) {
  return (cycleEndBlock - cycleStartBlock) * 3;
}

export function calcCycleEndPercent(cycleEnd: number, cycleLength: number) {
  return 1 - (cycleEnd / cycleLength);
}
